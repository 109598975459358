<template>
  <a-modal
    title="用户角色关联"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="当前关联角色">
              <div v-if="userRoleList.length > 0">
                <a-tag closable @close="deleteUserRole(item.id)" v-for="item in userRoleList" :key="item.id">
                  {{ item.role }}
                </a-tag>
              </div>
              <span v-else style="color: #909399">暂无角色</span>
            </a-form-item>
            <a-form-item label="增加关联角色">
              <a-select
                option-label-prop="tt"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'role_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入角色名称"
                :default-active-first-option="false"
                @search="this.search"
                @change="onChange"
              >
                <a-select-option v-for="d in roleList" :key="d.id" :tt="d.name" :disabled="isDisable(d.id)">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { oaUserRoleDelete, oaUserRoleList } from '@/api/oa_user'
import debounce from 'lodash/debounce'
import { oaRoleList } from '@/api/oa_role'

// 表单字段
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.search = debounce(this.onRoleSearch, 800)
    return {
      form: this.$form.createForm(this),
      roleList: [],
      userRoleList: [],
      role_id: null
    }
  },
  created () {
    this.getInfo()
    this.onRoleSearch(undefined)
  },
  methods: {
    isDisable (roleId) {
      const roleIds = this.userRoleList.map(item => item.role_id)
      return roleIds.includes(roleId)
    },
    deleteUserRole (id) {
      oaUserRoleDelete(id).then(res => {
        if (res.code === 1000) {
          this.getInfo()
        }
      })
    },
    getInfo () {
      oaUserRoleList({ user_id: this.model.id }).then((res) => {
        this.userRoleList = res.data.entries
      })
    },
    onChange (value) {
      if (value === undefined) {
        this.onRoleSearch(value)
      }
    },
    onRoleSearch (searchText) {
      oaRoleList({ name: searchText, user_id: this.model.id }).then((res) => {
        this.roleList = res.data.entries || []
      })
    }
  }
}
</script>
