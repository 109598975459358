var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"用户部门关联","width":680,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"当前关联部门"}},[(_vm.udList.length > 0)?_c('div',_vm._l((_vm.udList),function(item){return _c('a-tag',{key:item.id,attrs:{"closable":""},on:{"close":function($event){return _vm.deleteUserDepartment(item.id)}}},[_vm._v(" "+_vm._s(item.department.name)+" ")])}),1):_c('span',{staticStyle:{"color":"#909399"}},[_vm._v("暂无关联部门")])]),_c('a-form-item',{attrs:{"label":"增加关联部门"}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'department_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"allow-clear":"","dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.departmentTreeData,"placeholder":"请选择部门"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }