<template>
  <div>
    <a-modal
      title="用户关联合同"
      :width="680"
      :visible="visible"
      :confirmLoading="loading"
      :footer="null"
      @ok="() => { $emit('ok') }"
      @cancel="() => { $emit('cancel') }"
    >
      <a-spin :spinning="loading">
        <a-form :form="form" v-bind="formLayout">
          <a-row :gutter="[16,16]">
            <a-col :span="24">
              <a-form-item label="关联合同">
                <div v-if="userContractList.length > 0">
                  <a-tag closable @close="deleteUserContract(item.id)" v-for="item in userContractList" :key="item.id">
                    {{ item.company }}（{{ item.start_date }} - {{ item.end_date }}）
                  </a-tag>
                </div>
                <span v-else style="color: #909399">暂无关联合同</span>
              </a-form-item>
              <a-form-item label="增加合同">
                <a-button type="primary" icon="plus" @click="addUserContract">新建</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
    <a-modal
      title="创建用户合同"
      :width="780"
      :visible="createVisible"
      :confirmLoading="createLoading"
      @ok="createOK"
      @cancel="createCancel"
    >
      <a-spin :spinning="createLoading">
        <a-form :form="createForm">
          <a-form-item label="公司名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
              v-decorator="['company', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"
              placeholder="公司名称"/>
          </a-form-item>
          <a-form-item label="合同始期" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-date-picker
              v-decorator="['start_date', {rules: [{required: true, message: '请输入'}]}]"
              show-time
              format="YYYY-MM-DD"
              placeholder="合同始期"
              value-format="yyyy-MM-DD"/>
          </a-form-item>
          <a-form-item label="合同年限" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input-number v-decorator="['contract_years', {initialValue: 1, rules: [{required: true, message: '请输入'}]}]" placeholder="合同年限"/>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>

import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { oaEmployContractCreate, oaEmployContractDelete, oaEmployContractList } from '@/api/oa_contract'

// 表单字段
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      form: this.$form.createForm(this, { name: 'form' }),
      createForm: this.$form.createForm(this, { name: 'createForm' }),
      roleList: [],
      userContractList: [],
      role_id: null,
      createVisible: false,
      createLoading: false
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    isDisable (roleId) {
      const roleIds = this.userContractList.map(item => item.role_id)
      return roleIds.includes(roleId)
    },
    deleteUserContract (id) {
      oaEmployContractDelete(id).then(res => {
        if (res.code === 1000) {
          this.getInfo()
        }
      })
    },
    getInfo () {
      oaEmployContractList({ user_id: this.model.id }).then((res) => {
        this.userContractList = res.data.entries
      })
    },
    addUserContract () {
      this.createVisible = true
    },
    createCancel () {
      this.createVisible = false
    },
    createOK () {
      this.createForm.validateFields((errors, values) => {
        if (!errors) {
          values.oa_user_id = this.model.id
          values.end_date = this.calculateEndDate(values.start_date, values.contract_years)
          this.createLoading = true
          oaEmployContractCreate(values).then(res => {
            if (res.code === 1000) {
              this.createVisible = false
              // 重置表单数据
              this.createForm.resetFields()
              this.getInfo()
            }
          }).finally(() => {
            this.createLoading = false
          })
        } else {
          this.createLoading = false
        }
      })
    },
    calculateEndDate (startDate, years) {
      // 将开始时间转换为 Date 对象
      const start = new Date(startDate)
      // 计算结束时间
      const end = new Date(start)
      end.setFullYear(start.getFullYear() + years)

      // 格式化结束时间为 YYYY-MM-DD
      const year = end.getFullYear()
      const month = String(end.getMonth() + 1).padStart(2, '0') // 月份从 0 开始，需要加 1
      const day = String(end.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }
}
</script>
