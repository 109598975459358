<template>
  <a-modal
    title="用户部门关联"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="当前关联部门">
              <div v-if="udList.length > 0" >
                <a-tag closable @close="deleteUserDepartment(item.id)" v-for="item in udList" :key="item.id">
                  {{ item.department.name }}
                </a-tag>
              </div>
              <span v-else style="color: #909399">暂无关联部门</span>
            </a-form-item>
            <a-form-item label="增加关联部门">
              <a-tree-select
                style="width: 100%"
                allow-clear
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="departmentTreeData"
                v-decorator="[
                  'department_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请选择部门"
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { oaUserDepartmentCreate, oaUserDepartmentDelete, oaUserDepartmentList } from '@/api/oa_user'
import { oaDepartmentList } from '@/api/oa_department'

// 表单字段
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      tip: '',
      form: this.$form.createForm(this),
      departmentTreeData: [],
      udList: [],
      department_id: null
    }
  },
  created () {
    this.getInfo()
    this.handleDepartmentSearch()
  },
  methods: {
    deleteUserDepartment (id) {
      oaUserDepartmentDelete(id).then(res => {
        if (res.code === 1000) {
          this.getInfo()
        }
      })
    },
    getInfo () {
      oaUserDepartmentList({ user_id: this.model.id }).then((res) => {
        this.udList = res.data.entries
      })
    },
    handleDepartmentSearch () {
      oaDepartmentList().then((res) => {
        const result = res.data.entries || []
        this.departmentTreeData = this.buildTree(this.transformDepartments(result))
      })
    },
    transformDepartments (departments) {
      return departments.map(department => ({
        title: department.name,
        value: department.id,
        key: department.id,
        parent_department_id: department.parent_department_id,
        remark: department.remark
      }))
    },
    buildTree (departments) {
      const idToNodeMap = {}
      const tree = []
      // 初始化每个节点
      departments.forEach(department => {
        idToNodeMap[department.key] = { ...department, children: [] }
      })
      // 构建树状结构
      departments.forEach(department => {
        const parentId = department.parent_department_id
        if (parentId === -1) {
          tree.push(idToNodeMap[department.key])
        } else {
          idToNodeMap[parentId].children.push(idToNodeMap[department.key])
        }
      })
      return tree
    },
    adduc () {
      const param = Object.assign({ user_id: this.model.id, department_id: this.department_id })
      oaUserDepartmentCreate(param).then(res => {
        if (res.code === 1000) {
          this.form.setFieldsValue({
            corporation: ''
          })
          this.department_id = null
          this.getInfo()
        }
      })
    }
  }
}
</script>
