<template>
  <a-modal
    title="新建用户"
    :width="1280"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'user_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="用户名称"
                :default-active-first-option="false"
                @search="this.userSearch"
                @change="handleUserChange"
              >
                <a-select-option v-for="d in userList" :key="d.id" :tt="d.nickname">
                  {{ d.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input
                v-decorator="['name', {rules: [{required: true, message: '请输入'}, {min:2, max:10, message: '输入不符合长度限制'}]}]"
                placeholder="用户真实姓名"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="身份证" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['id_number', {rules: [{required: false, message: '请输入'}]}]" placeholder="身份证号"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select
                v-decorator="[
                  'gender',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择性别"
              >
                <a-select-option :value="1">
                  男
                </a-select-option>
                <a-select-option :value="2">
                  女
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="民族" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['ethnicity', {rules: [{required: false, message: '请输入'}, {max:64, message: '输入不符合长度限制'}]}]" placeholder="民族"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="籍贯" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['hometown', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]" placeholder="籍贯"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="出生地" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['birthplace', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]" placeholder="出生地"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="出生日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-date-picker
                v-decorator="['birth_day', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                show-time
                format="YYYY-MM-DD"
                placeholder="出生日期"
                value-format="yyyy-MM-DD"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="政治面貌" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['political_status', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]" placeholder="政治面貌"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="入党时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-date-picker
                v-decorator="['party_join_date']"
                show-time
                format="YYYY-MM-DD"
                placeholder="入党时间"
                value-format="yyyy-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="全日制教育学历" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['full_time_education', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="全日制教育学历"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="全日制教育学位" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['full_time_degree', {rules: [{required: false, message: '请输入'}, {max:100, message: '输入不符合长度限制'}]}]" placeholder="全日制教育学位"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="全日制教育毕业院校" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['full_time_university', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="全日制教育毕业院校"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="全日制教育专业" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['full_time_major', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="全日制教育专业"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="在职教育学历" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['part_time_education', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="在职教育学历"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="在职教育学位" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['part_time_degree', {rules: [{required: false, message: '请输入'}, {max:100, message: '输入不符合长度限制'}]}]" placeholder="在职教育学位"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="在职教育毕业院校" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['part_time_university', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="在职教育毕业院校"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="在职教育专业" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['part_time_major', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="在职教育专业"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="专业技术职务" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['technical_title', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="专业技术职务"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="参加工作时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-date-picker
                v-decorator="['work_start_date', {rules: [{required: false, message: '请输入'}]}]"
                show-time
                format="YYYY-MM-DD"
                placeholder="参加工作时间"
                value-format="yyyy-MM-DD"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="座机" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['landline_phone', {rules: [{required: false, message: '请输入'}, {max:20, message: '输入不符合长度限制'}]}]" placeholder="座机"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="入职时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-date-picker
                v-decorator="['join_company_date', {rules: [{required: false, message: '请输入'}]}]"
                show-time
                format="YYYY-MM-DD"
                placeholder="入职时间"
                value-format="yyyy-MM-DD"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['cell_phone1', {rules: [{required: false, message: '请输入'}, {max:20, message: '输入不符合长度限制'}]}]" placeholder="手机号"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备用手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['cell_phone2', {rules: [{required: false, message: '请输入'}, {max:20, message: '输入不符合长度限制'}]}]" placeholder="备用手机号"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="办公地点" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-textarea v-decorator="['office_location', {rules: [{required: false, message: '请输入'}, {max:255, message: '输入不符合长度限制'}]}]" placeholder="办公地点" :rows="1"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="工作简历" :labelCol="labelCol2" :wrapperCol="wrapperCol2">
              <a-upload
                v-show="false"
                class="detail_upload"
                name="file"
                multiple
                accept="image/png, image/jpeg, image/jpg"
                :action="actionUrl"
                list-type="picture-card"
                :file-list="detail_icon_url"
                :before-upload="beforeGoodsDetailUrlUpload"
                @preview="handlePreview"
                @change="handleDetailUrlChange"
                :customRequest="detailCustomRequest"
              ></a-upload>
              <quill-editor
                v-decorator="['work_resume', {rules: [{required: false, message: '请输入'}]}]"
                ref="myQuillEditor"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)"
                @change="onEditorChange($event)">
              </quill-editor>
              <a-input v-if="false" v-decorator="['work_resume', {rules: [{required: false, message: '请输入'}]}]" placeholder="工作简历"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// 表单字段
import debounce from 'lodash/debounce'
import { user_list } from '@/api/user'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { Quill } from 'vue-quill-editor/dist/ssr'
import axios from 'axios'
import { oss_token_list } from '@/api/oss_token'
const fonts = ['Sans-Serif', 'SimSun', 'FangSong', 'Serif', 'Monospace']
Quill.imports['formats/font'].whitelist = fonts

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: { quillEditor },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.userSearch = debounce(this.handleUserSearch, 800)
    return {
      userList: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      labelCol2: {
        xs: { span: 6 },
        sm: { span: 3 }
      },
      wrapperCol2: {
        xs: { span: 24 },
        sm: { span: 20 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      PoliticalStatus: [],
      actionUrl: 'https://static2.jnzbtz.com',
      detail_icon_url: [],
      work_resume_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block'],

              [{ 'header': 1 }, { 'header': 2 }], // custom button values
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
              [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
              [{ 'direction': 'rtl' }], // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
              [{ 'font': fonts }],
              [{ 'align': [] }],

              ['clean'], // remove formatting button
              ['link', 'image']
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  var element = document.querySelector('.detail_upload input')
                  element.click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          },
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          }
        },
        placeholder: '工作简历',
        theme: 'snow'
      }
    }
  },
  created () {
    this.initOss()
  },
  methods: {
    async initOss () {
      this.work_resume_multipart_params = await this.ossToken()
    },
    ossToken () {
      return new Promise((resolve, reject) => {
        oss_token_list().then(({ data }) => {
          const multipart_params = {
            OSSAccessKeyId: data.accessid,
            policy: data.policy,
            signature: data.signature
          }
          resolve(multipart_params)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    handleUserSearch (value) {
      user_list({ nickname: value }).then((res) => {
        const result = res.data.entries || []
        this.userList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleUserChange (value) {
      if (value === undefined) {
        this.handleUserSearch(value)
      }
    },
    onEditorBlur (quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus (quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady (quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange ({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.$emit('change', html)
    },
    beforeGoodsDetailUrlUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const lastName = file.lastModified + '.' + suffix
      this.work_resume_multipart_params.key = file.uid + '_' + lastName
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      if (isJpgOrPng && isLt2M) {
        this.detailCustomRequest(file)
      }
      return isJpgOrPng && isLt2M
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {})
      }
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    handleDetailUrlChange ({ fileList, file }) {
      this.detail_icon_url = fileList
      if (file.status === 'removed') {
        this.goods.detail_icon_url = undefined
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        // this.goods.detail_icon_url.push(this.actionUrl + '/' + this.work_resume_multipart_params.key)
        // 获取富文本组件实例
        const quill = this.$refs.myQuillEditor.quill
        // 如果上传成功
        if (file.status === 'done') {
          // 获取光标所在位置
          const length = quill.getSelection().index
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', this.actionUrl + '/' + this.work_resume_multipart_params.key)
          // 调整光标到最后
          quill.setSelection(length + 1)
        } else {
          // 提示信息，需引入Message
          this.$message.error('图片插入失败！')
        }
      }
    },
    detailCustomRequest (file) {
      setTimeout(() => {
        if (file !== undefined) {
          const fileList = []
          fileList.push({ uid: file.uid, lastModified: file.lastModified, name: file.name, file: file, status: 'done' })
          fileList.forEach((item) => {
            if (item.file.uid === undefined) {
              return
            }
            // 对上传的文件做大小的校验 不能大于20M并且不能超过10张
            const formData = new FormData()
            const name = item.file.name
            const suffix = name.substr(name.lastIndexOf('.') + 1)
            const lastName = item.file.lastModified + '.' + suffix
            const key = item.file.uid + '_' + lastName
            formData.append('policy', this.work_resume_multipart_params.policy)
            formData.append('OSSAccessKeyId', this.work_resume_multipart_params.OSSAccessKeyId)
            formData.append('success_action_status', '200')
            formData.append('signature', this.work_resume_multipart_params.signature)
            formData.append('key', key)
            formData.append('file', item.file)
            if (this.request === undefined) {
              this.request = axios.create({
                // API 请求的默认前缀
                baseURL: this.actionUrl,
                timeout: 10000 // 请求超时时间
              })
            }
            this.request({
              baseURL: this.actionUrl,
              method: 'post',
              data: formData
            }).then((Res) => {
              const pic = {
                thumbUrl: this.actionUrl + '/' + key,
                uid: item.file.uid,
                status: 'done',
                name: item.file.name,
                ...item.file
              }
              this.work_resume_multipart_params = pic
              const quill = this.$refs.myQuillEditor.quill
              // 获取光标所在位置
              const length = quill.getSelection().index
              // 插入图片，res为服务器返回的图片链接地址
              quill.insertEmbed(length, 'image', pic.thumbUrl)
              // 调整光标到最后
              quill.setSelection(length + 1)
            }).finally(() => {
              oss_token_list().then(({ data }) => {
                this.work_resume_multipart_params.OSSAccessKeyId = data.accessid
                this.work_resume_multipart_params.policy = data.policy
                this.work_resume_multipart_params.signature = data.signature
              })
            })
          })
        }
      }, 2000)
    }
  }
}
</script>
