<template>
  <a-modal
    :title="this.model.name"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    :footer="null"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <div v-html="this.model.work_resume"></div>
  </a-modal>
</template>

<script>
// 表单字段
import debounce from 'lodash/debounce'
import { oaUserList } from '@/api/oa_user'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.userSearch = debounce(this.handleUserSearch, 800)
    return {
      userList: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  methods: {
    handleUserSearch (value) {
      oaUserList({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.userList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleUserChange (value) {
      if (value === undefined) {
        this.handleUserSearch(value)
      }
    }
  }
}
</script>
