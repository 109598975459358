<template>
  <div>
    <a-modal
      title="用户关联职级"
      :width="780"
      :visible="visible"
      :confirmLoading="loading"
      :footer="null"
      @ok="() => { $emit('ok') }"
      @cancel="() => { $emit('cancel') }"
    >
      <a-spin :spinning="loading">
        <a-form :form="form" v-bind="formLayout">
          <a-row :gutter="[16,16]">
            <a-col :span="24">
              <a-form-item label="关联职级">
                <div v-if="userRankList.length > 0">
                  <a-tag closable @close="deleteUserRank(item.id)" v-for="item in userRankList" :key="item.id">
                    {{ item.name }} ( {{ item.start_date }} {{ item.end_date === '1970-01-01' || item.end_date === '3000-01-01' ? ' — ' : ' — ' + item.end_date }})
                  </a-tag>
                </div>
                <span v-else style="color: #909399">暂无关联职级</span>
              </a-form-item>
              <a-form-item label="添加职级">
                <a-button type="primary" icon="plus" @click="addUserRank">新建</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
    <a-modal
      title="创建用户职级"
      :width="780"
      :visible="createVisible"
      :confirmLoading="createLoading"
      @ok="createOK"
      @cancel="createCancel"
    >
      <a-spin :spinning="createLoading">
        <a-form :form="createForm">
          <a-form-item label="职级名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
              v-decorator="['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"
              placeholder="职级名称"/>
          </a-form-item>
          <a-form-item label="职级开始时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-date-picker
              v-decorator="['start_date', {rules: [{required: true, message: '请输入'}]}]"
              show-time
              format="YYYY-MM-DD"
              placeholder="开始时间"
              value-format="yyyy-MM-DD"/>
          </a-form-item>
          <a-form-item label="职级结束时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-date-picker
              v-decorator="['end_date', {rules: [{required: false, message: '请输入'}]}]"
              show-time
              format="YYYY-MM-DD"
              placeholder="结束时间"
              value-format="yyyy-MM-DD"/>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>

import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { oaUserRankCreate, oaUserRankDelete, oaUserRankList } from '@/api/oa_user'

// 表单字段
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      form: this.$form.createForm(this, { name: 'form' }),
      createForm: this.$form.createForm(this, { name: 'createForm' }),
      roleList: [],
      userRankList: [],
      role_id: null,
      createVisible: false,
      createLoading: false
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    isDisable (roleId) {
      const roleIds = this.userRankList.map(item => item.role_id)
      return roleIds.includes(roleId)
    },
    deleteUserRank (id) {
      oaUserRankDelete(id).then(res => {
        if (res.code === 1000) {
          this.getInfo()
        }
      })
    },
    getInfo () {
      oaUserRankList({ user_id: this.model.id }).then((res) => {
        this.userRankList = res.data.entries
      })
    },
    addUserRank () {
      this.createVisible = true
    },
    createCancel () {
      this.createVisible = false
    },
    createOK () {
      this.createForm.validateFields((errors, values) => {
        if (!errors) {
          values.oa_user_id = this.model.id
          this.createLoading = true
          oaUserRankCreate(values).then(res => {
            if (res.code === 1000) {
              this.createVisible = false
              // 重置表单数据
              this.createForm.resetFields()
              this.getInfo()
            }
          }).finally(() => {
            this.createLoading = false
          })
        } else {
          this.createLoading = false
        }
      })
    }
  }
}
</script>
