<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-if="!queryParam.id">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="姓名">
              <a-input v-model="queryParam.name" placeholder="请输入姓名" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="手机号">
              <a-input v-model="queryParam.cellphone" placeholder="请输入手机号" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="2" :xs="6">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
          <a-col :md="2" :xs="6">
            <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
          </a-col>
          <a-col :md="2" :xs="6">
            <a-button type="primary" icon="export" @click="handleExport">导出</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
      :scroll="{ x: 1000 }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 160px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleUserDepartment(record)">关联部门</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleRelatedRole(record)">关联角色</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleRelatedContract(record)">关联合同</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleRelatedRank(record)">关联职级</a>
              </a-menu-item>
              <a-menu-item v-if="record.work_resume">
                <a @click="handleSeeWorkResume(record)">查看简历</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <user-department-form
      ref="createUserDepartmentModal"
      :visible="userDepartmentVisible"
      v-if="userDepartmentVisible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleUserDepartmentCancel"
      @ok="handleUserDepartmentOk"
    />
    <user-role-form
      ref="createUserRoleModal"
      :visible="userRoleVisible"
      v-if="userRoleVisible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleUserRoleCancel"
      @ok="handleUserRoleOk"
    />
    <user-contract-form
      ref="readUserContractModal"
      :visible="userContractVisible"
      v-if="userContractVisible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleUserContractCancel"
      @ok="handleUserContractOk"
    />
    <user-rank-form
      ref="readUserRankModal"
      :visible="userRankVisible"
      v-if="userRankVisible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleUserRankCancel"
      @ok="handleUserRankOk"
    />
    <see-user-work-resume-form
      ref="readUserWordResumeModal"
      :visible="userWorkResumeVisible"
      v-if="userWorkResumeVisible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleUserWordResumeCancel"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '../modules/CreateForm'
import UserDepartmentForm from '../modules/UserDepartmentForm'
import UserRoleForm from '../modules/UserRoleForm'
import UserContractForm from '../modules/UserContractForm'
import UserRankForm from '../modules/UserRankForm'
import SeeUserWorkResumeForm from '../modules/SeeUserWorkResumeForm'

import {
  exportUser,
  oaUserCreate,
  oaUserDelete,
  oaUserDepartmentCreate,
  oaUserList,
  oaUserRoleCreate
} from '@/api/oa_user'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    UserDepartmentForm,
    UserRoleForm,
    UserContractForm,
    UserRankForm,
    SeeUserWorkResumeForm
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      userDepartmentVisible: false,
      userRoleVisible: false,
      userContractVisible: false,
      userRankVisible: false,
      userWorkResumeVisible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          fixed: 'left',
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 100,
          ellipsis: true
        },
        {
          title: '身份证',
          dataIndex: 'id_number',
          width: 200,
          ellipsis: true
        },
        {
          title: '性别',
          width: 100,
          align: 'center',
          dataIndex: 'gender',
          customRender: (text) => text === 1 ? '男' : '女'
        },
        {
          title: '出生日期',
          dataIndex: 'birth_day',
          width: 150,
          align: 'left',
          customRender: (text) => (text === '1970-01-01' && '--') || text
        },
        {
          title: '民族',
          dataIndex: 'ethnicity',
          ellipsis: true,
          width: 100,
          align: 'center'
        },
        {
          title: '籍贯',
          dataIndex: 'hometown',
          ellipsis: true,
          width: 120
        },
        {
          title: '出生地',
          dataIndex: 'birthplace',
          ellipsis: true,
          width: 150
        },
        {
          title: '政治面貌',
          dataIndex: 'political_status',
          width: 150,
          ellipsis: true
        },
        {
          title: '入党时间',
          dataIndex: 'party_join_date',
          width: 150,
          ellipsis: true,
          customRender: (text) => (text === '1970-01-01' && '--') || text
        },
        {
          title: '全日制教育学历',
          dataIndex: 'full_time_education',
          width: 150,
          ellipsis: true
        },
        {
          title: '全日制教育学位',
          dataIndex: 'full_time_degree',
          width: 150,
          ellipsis: true
        },
        {
          title: '全日制教育毕业院校',
          dataIndex: 'full_time_university',
          width: 150,
          ellipsis: true
        },
        {
          title: '全日制教育专业',
          dataIndex: 'full_time_major',
          width: 150,
          ellipsis: true
        },
        {
          title: '在职制教育学历',
          dataIndex: 'part_time_education',
          width: 150,
          ellipsis: true
        },
        {
          title: '在职制教育学位',
          dataIndex: 'part_time_degree',
          width: 150,
          ellipsis: true
        },
        {
          title: '在职制教育毕业院校',
          dataIndex: 'part_time_university',
          width: 150,
          ellipsis: true
        },
        {
          title: '在职制教育专业',
          dataIndex: 'part_time_major',
          width: 150,
          ellipsis: true
        },
        {
          title: '专业技术职务',
          dataIndex: 'technical_title',
          width: 150,
          ellipsis: true
        },
        {
          title: '参加工作时间',
          dataIndex: 'work_start_date',
          width: 150,
          ellipsis: true,
          customRender: (text) => (text === '1970-01-01' && '--') || text
        },
        {
          title: '入职时间',
          dataIndex: 'join_company_date',
          width: 150,
          ellipsis: true,
          customRender: (text) => (text === '1970-01-01' && '--') || text
        },
        {
          title: '座机',
          dataIndex: 'landline_phone',
          width: 150,
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'cell_phone1',
          width: 150,
          ellipsis: true
        },
        {
          title: '备用手机号',
          dataIndex: 'cell_phone2',
          width: 150,
          ellipsis: true
        },
        {
          title: '办公地点',
          dataIndex: 'office_location',
          width: 150,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }

      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return oaUserList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleExport () {
      exportUser(this.queryParam).then(res => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `用户.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleUserDepartmentCancel () {
      this.userDepartmentVisible = false
      const form = this.$refs.createUserDepartmentModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleUserRoleCancel () {
      this.userRoleVisible = false
      const form = this.$refs.createUserRoleModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleUserContractCancel () {
      this.userContractVisible = false
      const form = this.$refs.readUserContractModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleUserRankCancel () {
      this.userRankVisible = false
      const form = this.$refs.readUserRankModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleUserWordResumeCancel () {
      this.userWorkResumeVisible = false
      const form = this.$refs.readUserWordResumeModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDetail (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/electricity_meter_record/' + record.id })
    },
    handleDelete (record) {
      oaUserDelete(record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleUserDepartment (record) {
      this.mdl = record
      this.userDepartmentVisible = true
    },
    handleRelatedRole (record) {
      this.mdl = record
      this.userRoleVisible = true
    },
    handleRelatedContract (record) {
      this.mdl = record
      this.userContractVisible = true
    },
    handleRelatedRank (record) {
      this.mdl = record
      this.userRankVisible = true
    },
    handleSeeWorkResume (record) {
      this.mdl = record
      this.userWorkResumeVisible = true
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          oaUserCreate(values).then(res => {
            this.confirmLoading = false
            if (res.code === 1000) {
              this.visible = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUserDepartmentOk () {
      const form = this.$refs.createUserDepartmentModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.user_id = this.mdl.id
          oaUserDepartmentCreate(values).then(res => {
            this.confirmLoading = false
            if (res.code === 1000) {
              this.userDepartmentVisible = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUserRoleOk () {
      const form = this.$refs.createUserRoleModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.user_id = this.mdl.id
          oaUserRoleCreate(values).then(res => {
            this.confirmLoading = false
            if (res.code === 1000) {
              this.userRoleVisible = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUserContractOk () {
      this.handleUserContractCancel()
    },
    handleUserRankOk () {
      this.handleUserRankCancel()
    }
  }
}
</script>
