var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"用户角色关联","width":680,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"当前关联角色"}},[(_vm.userRoleList.length > 0)?_c('div',_vm._l((_vm.userRoleList),function(item){return _c('a-tag',{key:item.id,attrs:{"closable":""},on:{"close":function($event){return _vm.deleteUserRole(item.id)}}},[_vm._v(" "+_vm._s(item.role)+" ")])}),1):_c('span',{staticStyle:{"color":"#909399"}},[_vm._v("暂无角色")])]),_c('a-form-item',{attrs:{"label":"增加关联角色"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'role_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'role_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"请输入角色名称","default-active-first-option":false},on:{"search":this.search,"change":_vm.onChange}},_vm._l((_vm.roleList),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name,"disabled":_vm.isDisable(d.id)}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }